import React, { FC } from 'react'

export const BBBSeal: FC = () => {
  return (
    <div>
      <a
        href="https://www.bbb.org/us/az/phoenix/profile/life-insurance/inszu-llc-1126-1000074067/#sealclick"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://seal-central-northern-western-arizona.bbb.org/seals/darkgray-seal-120-61-bbb-1000074067.png"
          style={{ border: 0 }}
          alt="Inszu LLC BBB Business Review"
        />
      </a>
    </div>
  )
}