import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

const Layout: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <article className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-1 my-0 mx-auto max-w-7xl pt-0 pb-6 p-4">{children}</main>
      <Footer />
    </article>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
