import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  description?: string
  lang?: string
  meta?: HTMLMetaElement[]
  title: string
  jsonLd?: string
}

const Seo: FC<Props> = ({ description = ``, lang = `en`, meta = [], title, jsonLd }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        { name: `theme-color`, content: `#ECECEC` },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: 'wbyx2eqob917tka6vvsu2b67o7owii',
        },
      ].concat(meta)}
    >
      {jsonLd ? <script type="application/ld+json">{jsonLd}</script> : null}
    </Helmet>
  )
}

export default Seo
