import React, { FC } from 'react'

interface ContextMenuProps {
  onClose: () => void
}
export const ContextMenu: FC<ContextMenuProps> = ({ onClose }) => {
  return (
    <div className="bg-white rounded-md border border-gray-100 shadow-md p-4 pt-0 md:mx-0 w-full md:w-auto inline-block">
      <div className="flex justify-between items-center">
        <span className="text-gray-600 text-sm pr-16">We&apos;re here to help</span>
        <button className="p-4 pr-0 focus:outline-none" onClick={onClose}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3C13.3 -0.0999998 12.7 -0.0999998 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.0999998 0.7 -0.0999998 0.3 0.3C-0.0999998 0.7 -0.0999998 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.0999998 12.7 -0.0999998 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7Z"
              fill="#848497"
            />
          </svg>
        </button>
      </div>
      <div className="pt-4 flex items-center">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.776 13.856c.804.302 1.708.502 2.612.603 1.507.2 2.612 1.507 2.612 3.014v3.013c0 .804-.402 1.507-.904 2.11s-1.306.904-2.11.904h-.301c-3.215-.301-6.43-1.406-9.142-3.215a21.124 21.124 0 01-6.328-6.328C2.507 11.244 1.3 8.03 1 4.815c0-.804.2-1.607.703-2.21A3.104 3.104 0 013.713 1.5H7.026c1.507 0 2.813 1.105 3.014 2.612.1.904.301 1.808.603 2.612.402 1.105.1 2.41-.703 3.214l-.704.704c1.206 1.908 2.813 3.415 4.621 4.62l.704-.703c.904-.803 2.11-1.105 3.214-.703zm3.215 6.63v-3.013c0-.503-.402-.904-.804-1.005-1.004-.2-2.009-.402-3.014-.804-.401-.2-.803-.1-1.104.201l-1.306 1.306c-.302.402-.804.402-1.206.201a16.482 16.482 0 01-6.43-6.43c-.2-.4-.1-.903.202-1.205l1.306-1.205c.2-.301.301-.703.2-1.105a10.286 10.286 0 01-.803-3.014c-.1-.502-.502-.904-1.005-.904H4.014c-.302 0-.603.201-.804.402-.1.2-.2.402-.2.703.3 2.913 1.305 5.726 2.912 8.238 1.507 2.31 3.416 4.32 5.726 5.726 2.512 1.607 5.325 2.611 8.238 2.913.402 0 .603-.1.803-.302.201-.2.302-.401.302-.703z"
            fill="#2B3A3F"
          />
          <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="23">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.776 13.856c.804.302 1.708.502 2.612.603 1.507.2 2.612 1.507 2.612 3.014v3.013c0 .804-.402 1.507-.904 2.11s-1.306.904-2.11.904h-.301c-3.215-.301-6.43-1.406-9.142-3.215a21.124 21.124 0 01-6.328-6.328C2.507 11.244 1.3 8.03 1 4.815c0-.804.2-1.607.703-2.21A3.104 3.104 0 013.713 1.5H7.026c1.507 0 2.813 1.105 3.014 2.612.1.904.301 1.808.603 2.612.402 1.105.1 2.41-.703 3.214l-.704.704c1.206 1.908 2.813 3.415 4.621 4.62l.704-.703c.904-.803 2.11-1.105 3.214-.703zm3.215 6.63v-3.013c0-.503-.402-.904-.804-1.005-1.004-.2-2.009-.402-3.014-.804-.401-.2-.803-.1-1.104.201l-1.306 1.306c-.302.402-.804.402-1.206.201a16.482 16.482 0 01-6.43-6.43c-.2-.4-.1-.903.202-1.205l1.306-1.205c.2-.301.301-.703.2-1.105a10.286 10.286 0 01-.803-3.014c-.1-.502-.502-.904-1.005-.904H4.014c-.302 0-.603.201-.804.402-.1.2-.2.402-.2.703.3 2.913 1.305 5.726 2.912 8.238 1.507 2.31 3.416 4.32 5.726 5.726 2.512 1.607 5.325 2.611 8.238 2.913.402 0 .603-.1.803-.302.201-.2.302-.401.302-.703z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#a)" fill="#2B3A3F">
            <path d="M0 .5h24v24H0z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 .5h24v24H0V.5z" />
          </g>
        </svg>
        <a href="tel:4253639644" className="text-gray-900 hover:text-gray-800 ml-4">
          425-363-9644
        </a>
      </div>
      <div className="pt-4 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 3H4C2.3 3 1 4.3 1 6V18C1 19.7 2.3 21 4 21H20C21.7 21 23 19.7 23 18V6C23 4.3 21.7 3 20 3ZM4 5H20C20.4 5 20.7 5.2 20.9 5.6L12 11.8L3.1 5.6C3.3 5.2 3.6 5 4 5ZM20 19H4C3.4 19 3 18.6 3 18V7.9L11.4 13.8C11.6 13.9 11.8 14 12 14C12.2 14 12.4 13.9 12.6 13.8L21 7.9V18C21 18.6 20.6 19 20 19Z"
            fill="#33333D"
          />
        </svg>
        <a href="mailto:info@inszu.com" className="text-gray-900 hover:text-gray-800 ml-4">
          info@inszu.com
        </a>
      </div>
    </div>
  )
}
