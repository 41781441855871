import React, { FC } from 'react'


export const FooterLinks: FC = () => {
  return (
    <div className="grid grid-cols-2 grid-rows-1 md:grid-cols-1 md:grid-rows-2 gap-2">
      <a href="/privacy" className="block">
        <span className="block text-left text-gray-600">Privacy policy</span>
      </a>
      <a href="/terms" className="block">
        <span className="block text-right md:text-left text-gray-600">Terms of use</span>
      </a>
    </div>
  )
}