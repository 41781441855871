import React, { FC, useState } from 'react'
import { Link } from 'gatsby'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { ContextMenu } from './final-expense/context-menu'

const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { scrollY } = useViewportScroll()
  const background = useTransform(scrollY, [0, 100], ['rgba(255,255,255,0.05)', 'rgba(255,255,255,1)'])
  return (
    <motion.header style={{ background }} className="mb-6 sticky top-0 z-50">
      <div className="my-0 mx-auto max-w-7xl py-6 px-4 flex flex-row items-center">
        <h1 className="m-0">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <svg width="86" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M39.37 10.628c-.438 0-.806-.146-1.107-.44a1.471 1.471 0 01-.452-1.097c0-.423.15-.78.452-1.067.3-.288.669-.43 1.107-.43.423 0 .78.144 1.068.43.288.286.43.642.43 1.067 0 .439-.144.804-.43 1.096-.29.295-.645.44-1.068.44zm1.086 1.457v11.053h-2.258V12.085h2.258zM45.626 23.138H43.37V12.085h2.215v1.558c.328-.573.8-1.025 1.416-1.352.616-.328 1.313-.492 2.093-.492 1.216 0 2.244.396 3.077 1.19.849.805 1.272 1.947 1.272 3.424v6.727h-2.278v-6.093c0-.999-.239-1.774-.717-2.328-.479-.553-1.121-.83-1.93-.83-.848 0-1.542.297-2.082.893-.54.595-.81 1.363-.81 2.307v6.049h.001zM59.494 23.403c-.875 0-1.69-.199-2.441-.595a4.542 4.542 0 01-1.806-1.723l1.456-1.313c.328.534.748.946 1.262 1.24.514.294 1.037.44 1.569.44.534 0 .97-.132 1.313-.398.342-.266.514-.591.514-.974 0-.547-.314-.97-.943-1.271-.164-.095-.61-.279-1.334-.554-.738-.314-1.203-.526-1.396-.635-1.176-.616-1.763-1.523-1.763-2.728 0-.916.341-1.674 1.026-2.276.71-.602 1.628-.901 2.749-.901 1.573 0 2.782.567 3.63 1.703l-1.313 1.395c-.656-.806-1.464-1.21-2.421-1.21-.452 0-.81.116-1.077.348-.266.233-.4.52-.4.861 0 .547.307.95.923 1.21l1.128.471c.724.301 1.245.534 1.56.697 1.258.656 1.887 1.586 1.887 2.788 0 .972-.384 1.785-1.15 2.44-.764.658-1.755.985-2.973.985zM74.102 13.52l-6.033 7.69h6.216v1.928h-9.293V21.64l6.052-7.608H65.32v-1.928h8.782v1.414zM81.137 23.403c-1.477 0-2.653-.431-3.528-1.29-.876-.862-1.314-2.03-1.314-3.508v-6.52h2.257v6.5c0 .835.228 1.497.687 1.989.459.491 1.09.737 1.898.737.82 0 1.456-.24 1.908-.718.452-.478.678-1.149.678-2.01v-6.498H86v6.52c0 1.504-.43 2.68-1.291 3.527-.862.848-2.052 1.271-3.572 1.271z"
                fill="#2B3A3F"
              />
              <path d="M25.057 10.685a4.831 4.831 0 100-9.662 4.831 4.831 0 000 9.662z" fill="url(#paint0_linear)" />
              <path
                d="M4.36 9.773a4.36 4.36 0 100 8.72h8.06v8.147a4.36 4.36 0 008.72 0v-8.148l-3.67-5.407-5.05-3.312H4.36z"
                fill="#2B3A3F"
              />
              <path
                d="M29.145 10.568a6.241 6.241 0 01-10.327-4.714c0-1.545.561-2.958 1.491-4.047a4.358 4.358 0 00-7.89 2.553v5.413l8.719 8.717h5.502A4.36 4.36 0 0031 14.13a4.351 4.351 0 00-1.855-3.562z"
                fill="url(#paint1_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="21.644"
                  y1="9.261"
                  x2="28.477"
                  y2="2.427"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F5876B" />
                  <stop offset="1" stopColor="#F5B68F" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1="7.464"
                  y1="23.49"
                  x2="24.813"
                  y2="6.14"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#7BD6C7" />
                  <stop offset="1" stopColor="#BDF4E7" />
                </linearGradient>
              </defs>
            </svg>
          </Link>
        </h1>
        <nav className="flex-1 ml-4 flex flex-row justify-end items-center relative">
          <div className="absolute right-0 top-0 z-50">
            {menuOpen ? <ContextMenu onClose={() => setMenuOpen(false)} /> : null}
          </div>
          <button
            className="flex items-center focus:outline-none"
            onClick={() => setMenuOpen(true)}
          >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.776 13.856c.804.302 1.708.502 2.612.603 1.507.2 2.612 1.507 2.612 3.014v3.013c0 .804-.402 1.507-.904 2.11s-1.306.904-2.11.904h-.301c-3.215-.301-6.43-1.406-9.142-3.215a21.124 21.124 0 01-6.328-6.328C2.507 11.244 1.3 8.03 1 4.815c0-.804.2-1.607.703-2.21A3.104 3.104 0 013.713 1.5H7.026c1.507 0 2.813 1.105 3.014 2.612.1.904.301 1.808.603 2.612.402 1.105.1 2.41-.703 3.214l-.704.704c1.206 1.908 2.813 3.415 4.621 4.62l.704-.703c.904-.803 2.11-1.105 3.214-.703zm3.215 6.63v-3.013c0-.503-.402-.904-.804-1.005-1.004-.2-2.009-.402-3.014-.804-.401-.2-.803-.1-1.104.201l-1.306 1.306c-.302.402-.804.402-1.206.201a16.482 16.482 0 01-6.43-6.43c-.2-.4-.1-.903.202-1.205l1.306-1.205c.2-.301.301-.703.2-1.105a10.286 10.286 0 01-.803-3.014c-.1-.502-.502-.904-1.005-.904H4.014c-.302 0-.603.201-.804.402-.1.2-.2.402-.2.703.3 2.913 1.305 5.726 2.912 8.238 1.507 2.31 3.416 4.32 5.726 5.726 2.512 1.607 5.325 2.611 8.238 2.913.402 0 .603-.1.803-.302.201-.2.302-.401.302-.703z"
                fill="#2B3A3F"
              />
              <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="23">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.776 13.856c.804.302 1.708.502 2.612.603 1.507.2 2.612 1.507 2.612 3.014v3.013c0 .804-.402 1.507-.904 2.11s-1.306.904-2.11.904h-.301c-3.215-.301-6.43-1.406-9.142-3.215a21.124 21.124 0 01-6.328-6.328C2.507 11.244 1.3 8.03 1 4.815c0-.804.2-1.607.703-2.21A3.104 3.104 0 013.713 1.5H7.026c1.507 0 2.813 1.105 3.014 2.612.1.904.301 1.808.603 2.612.402 1.105.1 2.41-.703 3.214l-.704.704c1.206 1.908 2.813 3.415 4.621 4.62l.704-.703c.904-.803 2.11-1.105 3.214-.703zm3.215 6.63v-3.013c0-.503-.402-.904-.804-1.005-1.004-.2-2.009-.402-3.014-.804-.401-.2-.803-.1-1.104.201l-1.306 1.306c-.302.402-.804.402-1.206.201a16.482 16.482 0 01-6.43-6.43c-.2-.4-.1-.903.202-1.205l1.306-1.205c.2-.301.301-.703.2-1.105a10.286 10.286 0 01-.803-3.014c-.1-.502-.502-.904-1.005-.904H4.014c-.302 0-.603.201-.804.402-.1.2-.2.402-.2.703.3 2.913 1.305 5.726 2.912 8.238 1.507 2.31 3.416 4.32 5.726 5.726 2.512 1.607 5.325 2.611 8.238 2.913.402 0 .603-.1.803-.302.201-.2.302-.401.302-.703z"
                  fill="#fff"
                />
              </mask>
              <g mask="url(#a)" fill="#2B3A3F">
                <path d="M0 .5h24v24H0z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 .5h24v24H0V.5z" />
              </g>
            </svg>
            <span className="text-base leading-relaxed ml-2">Get Help</span>
          </button>
        </nav>
      </div>
    </motion.header>
  )
}

export default Header
